"use client";

import { graphql, useFragment } from "react-relay";
import { createContext, useContext } from "react";
import {
  OrganizationContext_organization$data,
  OrganizationContext_organization$key,
} from "@generated/OrganizationContext_organization.graphql";

interface Props {
  organization: OrganizationContext_organization$key;
  hideViewerStudent?: boolean;
}

const OrganizationContext = createContext<{
  fragment: OrganizationContext_organization$key | null;
  data: OrganizationContext_organization$data | null;
}>({ data: null, fragment: null });

const OrganizationContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  organization,
  hideViewerStudent,
}) => {
  const data = useFragment(
    graphql`
      fragment OrganizationContext_organization on Organization {
        id
        slug
        status
        name
        image(size: 360)
        viewerMembership {
          role
        }
        viewerStudent {
          id
          email
          normalizedEmail
          givenName
          lastName
          image(size: 360)
          createdAt
          audienceGroups {
            id
            slug
            label
            status
            hidden
          }
        }
      }
    `,
    organization,
  );

  return (
    <OrganizationContext.Provider
      value={{
        fragment: organization,
        data: hideViewerStudent ? { ...data, viewerStudent: null } : data,
      }}
      key={data.id}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => {
  const context = useContext(OrganizationContext);

  if (!context.data) {
    throw new Error(
      "No organization data set, use OrganizationContextProvider to set one",
    );
  }

  return context.data;
};

export const useViewer = () => {
  const context = useContext(OrganizationContext);

  if (!context) {
    throw new Error(
      "No organization data set, use OrganizationContextProvider to set one",
    );
  }

  return context.data?.viewerStudent ?? null;
};

export const useOrganizationFragment = () => {
  const context = useContext(OrganizationContext);

  if (!context.fragment) {
    throw new Error(
      "No organization fragment set, use OrganizationContextProvider to set one",
    );
  }

  return context.fragment;
};

export default OrganizationContextProvider;
