import {
  Environment,
  Network,
  RecordSource,
  Store,
  RequestParameters,
  QueryResponseCache,
  Variables,
  GraphQLResponse,
  CacheConfig,
  RelayFeatureFlags,
} from "relay-runtime";

// enable relay resolvers
RelayFeatureFlags.ENABLE_RELAY_RESOLVERS = true;

const IS_SERVER = typeof window === typeof undefined;
// requests are proxied to the backend frpm /proxy/graphql
const HTTP_ENDPOINT = IS_SERVER
  ? process.env.NEXT_PUBLIC_GRAPHQL_URL!
  : "/api/proxy/graphql";
const CACHE_TTL = 5 * 1000; // 5 seconds, to resolve preloaded results

export async function networkFetch(
  request: RequestParameters,
  variables: Variables,
): Promise<GraphQLResponse> {
  const resp = await fetch(HTTP_ENDPOINT, {
    method: "POST",
    credentials: "include",
    cache: "no-store",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Cookie: IS_SERVER
        ? // TODO: is there a better way to do this? https://github.com/andreizanik/cookies-next/issues/44#issuecomment-1696663466
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          require("next/headers").cookies().toString()
        : undefined,
    },
    body: JSON.stringify({
      query: request.text,
      variables,
    }),
  });
  const json = await resp.json();

  // GraphQL returns exceptions (for example, a missing required variable) in the "errors"
  // property of the response. If any exceptions occurred when processing the request,
  // throw an error to indicate to the developer what went wrong.
  if (Array.isArray(json.errors)) {
    console.error(json.errors);
    throw new Error(
      `Error fetching GraphQL query '${
        request.name
      }' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
        json.errors,
      )}`,
    );
  }

  return json;
}
function createNetwork(responseCache: QueryResponseCache) {
  async function fetchResponse(
    params: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
  ) {
    const isQuery = params.operationKind === "query";
    const cacheKey = params.id ?? params.cacheID;
    const forceFetch = cacheConfig && cacheConfig.force;
    if (responseCache != null && isQuery && !forceFetch) {
      const fromCache = responseCache.get(cacheKey, variables);
      if (fromCache != null) {
        return Promise.resolve(fromCache);
      }
    }

    return networkFetch(params, variables);
  }

  const network = Network.create(fetchResponse);
  return network;
}

function createQueryCache() {
  return new QueryResponseCache({
    size: 100,
    ttl: CACHE_TTL,
  });
}

export function createEnvironment() {
  const cache = createQueryCache();
  const network = createNetwork(cache);
  const store = new Store(RecordSource.create());

  const environment = new Environment({
    network,
    store,
    isServer: typeof window === "undefined",
  });

  responseCacheByEnvironment.set(environment, cache);

  return environment;
}

const responseCacheByEnvironment = new WeakMap<
  Environment,
  QueryResponseCache
>();

export function getCacheByEnvironment(environment: Environment) {
  return responseCacheByEnvironment.get(environment);
}
