"use client";

import React from "react";
import { graphql, useFragment } from "react-relay";
import dynamic from "next/dynamic";
import Script from "next/script";
import { OrganizationIntegrations$key } from "@generated/OrganizationIntegrations.graphql";

const GoogleTagManager = dynamic(() =>
  import("@next/third-parties/google").then(
    (module) => module.GoogleTagManager,
  ),
);
const GoogleAnalytics = dynamic(() =>
  import("@next/third-parties/google").then((module) => module.GoogleAnalytics),
);
const MetaPixel = dynamic(() => import("@/components/MetaPixel"));

const OrganizationIntegrations: React.FC<{
  organization: OrganizationIntegrations$key;
}> = ({ organization }) => {
  const data = useFragment(
    graphql`
      fragment OrganizationIntegrations on Organization {
        websiteSettings {
          googleAnalyticsId
          googleTagManagerId
          metaPixelId
          clarityProjectId
          jivoChatId
        }
      }
    `,
    organization,
  );

  return (
    <>
      {data.websiteSettings?.googleAnalyticsId && (
        <GoogleAnalytics gaId={data.websiteSettings.googleAnalyticsId} />
      )}
      {data.websiteSettings?.googleTagManagerId && (
        <GoogleTagManager gtmId={data.websiteSettings.googleTagManagerId} />
      )}
      {data.websiteSettings?.metaPixelId && (
        <MetaPixel pixelId={data.websiteSettings.metaPixelId} />
      )}
      {data.websiteSettings?.clarityProjectId && (
        <>
          <Script
            src={`https://www.clarity.ms/tag/${data.websiteSettings.clarityProjectId}`}
          />
          <Script
            id="ms_clarity_org"
            dangerouslySetInnerHTML={{
              __html: ` (function(c,l,a){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    })(window, document, "clarity");`,
            }}
          />
        </>
      )}
      {data.websiteSettings?.jivoChatId && (
        <Script
          id="jivo_chat_org"
          src={`//code.jivosite.com/widget/${data.websiteSettings.jivoChatId}`}
          async={true}
        />
      )}
    </>
  );
};

export default OrganizationIntegrations;
