"use client";

import React from "react";
import { SerializablePreloadedQuery } from "@/relay/loadSerializableQuery";
import { graphql, usePreloadedQuery, useRelayEnvironment } from "react-relay";
import useSerializablePreloadedQuery from "@/relay/useSerializablePreloadedQuery";
import { notFound } from "next/navigation";
import OrganizationStudentLayoutQueryNode, {
  OrganizationStudentLayoutQuery,
} from "@generated/OrganizationStudentLayoutQuery.graphql";
import OrganizationContextProvider from "@/student/context/OrganizationContext";
import OrganizationIntegrations from "./OrganizationIntegrations";

const OrganizationStudentLayout: React.FC<
  React.PropsWithChildren<{
    preloadedQuery: SerializablePreloadedQuery<
      typeof OrganizationStudentLayoutQueryNode,
      OrganizationStudentLayoutQuery
    >;
  }>
> = ({ preloadedQuery, children }) => {
  const environment = useRelayEnvironment();

  const queryRef = useSerializablePreloadedQuery(environment, preloadedQuery);

  const data = usePreloadedQuery(
    graphql`
      query OrganizationStudentLayoutQuery($slug: String!) {
        organization(slug: $slug) {
          ...OrganizationContext_organization
          ...OrganizationIntegrations
        }
      }
    `,
    queryRef,
  );

  if (!data.organization) {
    notFound();
  }

  return (
    <OrganizationContextProvider organization={data.organization}>
      <OrganizationIntegrations organization={data.organization} />
      {children}
    </OrganizationContextProvider>
  );
};

export default OrganizationStudentLayout;
