/**
 * @generated SignedSource<<070e2b50ccd553e082203545b40fb903>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type OrganizationStudentLayoutQuery$variables = {
  slug: string;
};
export type OrganizationStudentLayoutQuery$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationContext_organization" | "OrganizationIntegrations">;
  } | null | undefined;
};
export type OrganizationStudentLayoutQuery = {
  response: OrganizationStudentLayoutQuery$data;
  variables: OrganizationStudentLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "size",
      "value": 360
    }
  ],
  "kind": "ScalarField",
  "name": "image",
  "storageKey": "image(size:360)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationStudentLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationContext_organization"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationIntegrations"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationStudentLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMember",
            "kind": "LinkedField",
            "name": "viewerMembership",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationStudent",
            "kind": "LinkedField",
            "name": "viewerStudent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "normalizedEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "givenName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AudienceGroup",
                "kind": "LinkedField",
                "name": "audienceGroups",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hidden",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WebsiteSettings",
            "kind": "LinkedField",
            "name": "websiteSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "googleAnalyticsId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "googleTagManagerId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metaPixelId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clarityProjectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jivoChatId",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b993ae1786a420a6b7b849496689ac7d",
    "id": null,
    "metadata": {},
    "name": "OrganizationStudentLayoutQuery",
    "operationKind": "query",
    "text": "query OrganizationStudentLayoutQuery(\n  $slug: String!\n) {\n  organization(slug: $slug) {\n    ...OrganizationContext_organization\n    ...OrganizationIntegrations\n    id\n  }\n}\n\nfragment OrganizationContext_organization on Organization {\n  id\n  slug\n  status\n  name\n  image(size: 360)\n  viewerMembership {\n    role\n    id\n  }\n  viewerStudent {\n    id\n    email\n    normalizedEmail\n    givenName\n    lastName\n    image(size: 360)\n    createdAt\n    audienceGroups {\n      id\n      slug\n      label\n      status\n      hidden\n    }\n  }\n}\n\nfragment OrganizationIntegrations on Organization {\n  websiteSettings {\n    googleAnalyticsId\n    googleTagManagerId\n    metaPixelId\n    clarityProjectId\n    jivoChatId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1f503f964728fdea2f2dc154d4b2c4f0";

export default node;
